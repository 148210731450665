/** @jsx jsx */

import { jsx } from 'theme-ui'
import { motion } from 'framer-motion'

const WiperCard = ({ children, ...props }) => {
  return (
    <motion.div
      initial='initial'
      animate='animate'

      {...props}
    >
      {children}
    </motion.div>
  )
}

export default WiperCard
