/** @jsx jsx */

import { jsx, IconButton } from 'theme-ui'

const FooterIcon = ({ Icon, href, title, ...props }) => (
  <IconButton
    {...props}
    as='a'
    href={href}
    target='_blank'
    rel='nofollow noopener noreferrer'
  >
    <Icon title={title} />
  </IconButton>
)

export default FooterIcon
