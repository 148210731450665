/** @jsx jsx */

import { jsx, Link } from 'theme-ui'

const ExternalLink = ({ href, children, ...props }) => (
  <Link
    {...props}

    sx={{
      color: 'primary'
    }}

    href={href}
    target='_blank'
    rel='nofollow noopener noreferrer'
  >
    {children}
  </Link>
)

export default ExternalLink
