export const fadeInUp = () => ({
  initial: {
    y: 20,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    y: 20,
    opacity: 0,
  },
})

export const fadeInRight = () => ({
  initial: {
    x: -20,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration:0.5,
    },
  },
  exit: {
    x: -20,
    opacity: 0,
  },
})

export const circleAnimation = () => ({
  initial: {
    style: 'transform: scale(0); transform-origin: 128px 128px'
  },
  animate: {
    style: 'transform: scale(1); transform-origin: 128px 128px',
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
})

export const poleAnimation = () => ({
  initial: {
    height: 0,
  },
  animate: {
    height: 196,
    transition: {
      delay: 0.7,
      duration: 0.5,
      ease: 'easeOut',
    },
  },

})

export const flagAnimation = () => ({
  initial: {
    width: 0,
  },
  animate: {
    width: 153,
    transition: {
      delay: 1.25,
      duration: 0.5,
      ease: 'easeOut',
    },
  },
})

export const wiperAnimation = (duration, delay) => ({
  initial: {
    clipPath: 'polygon(0% 0%,0% 0%,0% 100%,0% 100%)',
  },
  animate: {
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    transition: {
      delay: delay,
      duration: duration,
      ease: 'easeOut',
    },
  },
})

export const pepegaAnimation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0,
    }
  }
}
