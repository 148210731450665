/** @jsx jsx */

import { Link } from 'gatsby'
import { jsx, Image } from 'theme-ui'

const SponsorLink = ({ Logo, href, title, ...props }) => (
    <Link
        {...props}
        as='a'
        href={href}
        target='_blank'
        rel='nofollow noopener noreferrer'
        sx={{ display: 'block', margin: 'auto', width: 'fit-content', p: 2 }}
    >
        <Image src={Logo} alt={title} sx={{ width: 100, display: 'block', m: 'auto' }} />
    </Link>
)

export default SponsorLink
