/** @jsx jsx */

import { jsx } from 'theme-ui'

const InlineList = ({ items, ...props }) => (
    <div {...props}>
        {items.map((item, index) => (
            <span key={index}>
                {item + (index < items.length - 1 ? '  •  ' : '')}
            </span>
        ))}
    </div>
)

export default InlineList
