/** @jsx jsx */

import { useStaticQuery, graphql } from 'gatsby'
import { jsx, Flex, Box, Heading, Paragraph, Text, Themed, Grid } from 'theme-ui'
import { motion } from 'framer-motion'
import { poleAnimation, flagAnimation, wiperAnimation, fadeInRight, circleAnimation, pepegaAnimation } from '../animation/animations'
import { FaEnvelope, FaDiscord, FaTwitter } from 'react-icons/fa'
import { useInView } from 'react-intersection-observer'
import { Global, css } from '@emotion/react'

import FooterIcon from '../components/iconbutton'
import WiperCard from '../components/wiper'
import InfoCard from '../components/card'
import ExternalLink from '../components/externallink'
import Seo from '../components/helmet'
import SponsorLink from '../components/sponsorlink'
import InlineList from '../components/inlinelist'

const icons = { FaEnvelope, FaDiscord, FaTwitter }

const IndexPage = () => {
  const { site: { siteMetadata: { socials } } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            socials {
              name
              href
              icon
            }
          }
        }
      }
    `
  )

  const [ref, inView] = useInView({
    triggerOnce: true
  })

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100vh',
      }}
    >
      <Seo />
      <Global
        styles={css`
          html {
            overflow-y: scroll;
          }

          ::-webkit-scrollbar {
            background: black;
            width: 5px;
          }

          ::-webkit-scrollbar-thumb {
            background: gray;
          }
        `}
      />
      <Box
        sx={{
          display: 'block',
          bg: 'background',
          color: 'text',
          height: '100vh',
          position: 'relative'
        }}
      >
        <motion.div
          sx={{
            bg: 'background',
            color: 'text',
            py: [4, 4, 5],
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <motion.div
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'min-content'
            }}
          >
            <motion.svg fill="none" viewBox="0 0 256 256" initial="initial" animate="animate" variants={pepegaAnimation}>
              <g clipPath="url(#a)">
                <path fill="url(#b)" d="M0 0h256v256H0z" />
                <rect width="10" height="196" x="54" y="52" fill="#000" rx="5" mask="url(#d)" />
                <g mask="url(#e)">
                  <path d="M59 73c0-2 1-4 4-5l49-10c5-1 11 1 15 4 3 3 8 4 13 4l58-8c3-1 5 2 5 5v99c0 2-2 5-4 5l-59 8c-5 1-10-1-13-4-4-3-10-4-15-3l-47 9c-5 1-6-1-6-4V73Z" filter="url(#c)" sx={{ fill: 'primary' }} />
                  <circle cx="128" cy="118" r="28" strokeWidth="4" sx={{ stroke: 'text' }} />
                  <path strokeLinejoin="round" strokeWidth="4" d="m164 90-36 56-36-56Z" sx={{ stroke: 'text' }} />
                  <path strokeWidth="4" d="M128 146V88" sx={{ stroke: 'text' }} />
                </g>
              </g>
              <defs>
                <linearGradient id="b" x1="185" x2="41" y1="26" y2="226" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#F8F7F2" />
                  <stop offset="1" stopColor="#761670" />
                </linearGradient>
                <clipPath id="a">
                  <motion.circle cx="128" cy="128" r="128" variants={circleAnimation()} />
                </clipPath>
                <filter id="c">
                  <feDropShadow dx="-5" dy="5" stdDeviation="0" />
                </filter>
                <mask id="d" maskUnits="userSpaceOnUse">
                  <motion.rect variants={poleAnimation()} x="50" y="47" width="20" fill="white" transform="scale(1,-1) translate(0,-285)"></motion.rect>
                </mask>
                <mask id="e" maskUnits="userSpaceOnUse">
                  <motion.rect variants={flagAnimation()} x="50" y="50" height="140" fill="white"></motion.rect>
                </mask>
              </defs>
            </motion.svg>
            <WiperCard
              sx={{
                fontWeight: 900,
                fontSize: [6, 6, 7],
                pt: 3,
                width: 'max-content',
              }}

              variants={wiperAnimation(0.5, 1.8)}
            >
              tjctf ’24
            </WiperCard>
            <WiperCard
              sx={{
                fontSize: [4, 4, 5],
                fontWeight: 500,
                pb: 2,
              }}

              variants={wiperAnimation(0.5, 2.05)}
            >
              May 17-19
            </WiperCard>
            <WiperCard
              sx={{
                background: 'primary',
                textAlign: 'center',
                mt: 3,
                fontSize: 4,
              }}

              variants={wiperAnimation(0.5, 2.3)}
            >
              <ExternalLink href="https://ctf.tjctf.org/"
                sx={{
                  color: 'text',
                  textDecoration: 'none',
                  px: 4,
                  py: 3,
                  display: 'inline-block',
                }}
              >
                compete
              </ExternalLink>
            </WiperCard>
          </motion.div>
        </motion.div>
      </Box>
      <motion.div
        sx={{
          bg: 'background',
          color: 'text',
          height: '100vh',
          flexDirection: 'column',
        }}

        initial={{
          display: 'none'
        }}
        animate={{
          display: 'flex',
        }}
        transition={{
          delay: 2.8,
          duration: 0,
        }}

      >
        <Flex
          sx={{
            pt: 4,
            m: 'auto',
            maxWidth: 'container',
            width: '90%',
            fontFamily: 'body',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <motion.div
            ref={ref}

            sx={{
              fontSize: 6,
              fontWeight: 700,
              display: 'flex',
              alignItems: 'center',
            }}

            initial='initial'
            animate={inView ? 'animate' : 'none'}
            variants={fadeInRight()}
          >
            <Text>
              tjctf ’24
            </Text>
          </motion.div>
          <Grid columns={[1, null, 4]} rows={'auto 1fr auto'} gap={4}
            sx={{
              my: 4,
              height: '100%'
            }}
          >
            <InfoCard
              sx={{
                gridColumn: ['1', null, '1 / span 2'],
                gridRow: '1'
              }}
            >
              <Heading>What is TJCTF?</Heading>
              <Paragraph>
                <Themed.b>TJCTF</Themed.b> is an international cybersecurity competition hosted by <ExternalLink href='https://tjcsec.club'>TJCSC</ExternalLink>, a group of students from <ExternalLink href='https://tjhsst.fcps.edu/'>Thomas Jefferson High School for Science and Technology</ExternalLink> in Northern Virginia. The competition consists of a variety of computer science and cybersecurity challenges in an online, jeopardy-style format. You’ll earn points through solving challenges in categories such as binary exploitation, reverse engineering, cryptography, and web. For more information, please join our <ExternalLink href='https://discord.gg/w5xapGP'>Discord</ExternalLink> to learn more about the competition and get notified when the event nears!
              </Paragraph>
            </InfoCard>
            <InfoCard
              sx={{
                gridColumn: ['1', null, '1 / span 2'],
                gridRow: '2'
              }}
            >
              <Heading>What if I’m new?</Heading>
              <Paragraph>
                That’s awesome—welcome! We have challenges available for players of <Themed.b>all experience levels</Themed.b>, including those with limited or no programming and cybersecurity experience. You should still be able to learn new things and enjoy the competition without CTF experience. Whether TJCTF is your first CTF or your hundredth, we’re excited to share a variety of challenges for you to dive into!
              </Paragraph>
              <Heading>Eligibility</Heading>
              <Paragraph>
                The competition is open all players, but challenges are aimed towards <Themed.b>high school students</Themed.b>. Teams are limited to 5 or fewer players. If you need help finding a team, join our Discord to find others in the same boat!
              </Paragraph>
            </InfoCard>
            <InfoCard
              sx={{
                gridColumn: ['1', null, '3 / span 2'],
                gridRow: ['3', null, '1 / span 2'],
              }}
            >
              <Heading sx={{ textAlign: 'center' }}>Prizes</Heading>

              <Paragraph>
                The <Themed.b>US High School Division</Themed.b> has a prize pool of <Themed.b>$2,250</Themed.b>:
              </Paragraph>
              <InlineList sx={{ textAlign: 'center' }} items={[
                '1st place: $1,000',
                '2nd place: $750',
                '3rd place: $500',
              ]} />
              <Paragraph>
                The <Themed.b>Open Division</Themed.b> has a prize pool of <Themed.b>$500</Themed.b>:
              </Paragraph>
              <InlineList sx={{ textAlign: 'center' }} items={[
                '1st place: $250',
                '2nd place: $150',
                '3rd place: $100',
              ]} />
              <Paragraph>
                All winners will also be awarded <ExternalLink href='/sponsors/zellic-swag.jpeg'>Zellic swag</ExternalLink>.
              </Paragraph>
              <Heading sx={{ textAlign: 'center' }}>Sponsors</Heading>
              <div sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 3 }}>
                <div>
                  <SponsorLink Logo='/sponsors/trail-of-bits.png' href='https://www.trailofbits.com/' title='Trail of Bits' />
                  <Paragraph sx={{ fontSize: 'small' }}>
                    Since 2012, <ExternalLink href='https://www.trailofbits.com/'>Trail of Bits</ExternalLink> has helped secure some of the world's most targeted organizations and devices. We combine high-end security research with a real-world attacker mentality to reduce risk and fortify code.
                    As a cybersecurity research and consulting firm, we serve clients in the defense, tech, finance, and blockchain industries. Our teams help with their most difficult security challenges by designing and building new technology, researching new techniques to advance the state of practice, and reviewing the security of the latest available technology products before they hit the market.
                  </Paragraph>
                </div>
                <div>
                  <SponsorLink Logo='/sponsors/zellic.svg' href='https://zellic.io/' title='Zellic' />
                  <Paragraph sx={{ fontSize: 'small' }}>
                    <ExternalLink href='https://zellic.io/'>Zellic</ExternalLink> is a security research firm. We hire top CTF talent to solve the world's most critical security problems. We specialize in ZKPs, cryptography, web app security, smart contracts, and blockchain L1/L2s. Before Zellic, we previously founded perfect blue, the #1 CTF team in 2020 and 2021. You're a good fit for Zellic if you have extensive real-world experience in vulnerability research (VR), binary exploitation, reverse engineering (RE), cryptography, or web application security. We hire internationally and offer competitive salaries and a comprehensive benefits package.
                    To learn more about Zellic, check out our <ExternalLink href='https://www.zellic.io/blog/the-auditooor-grindset'>blog</ExternalLink>.
                  </Paragraph>
                  <Paragraph sx={{ fontSize: 'small', textAlign: 'center' }}>
                    Work at Zellic: <ExternalLink href='mailto:jobs@zellic.io'>jobs@zellic.io</ExternalLink> | <ExternalLink href='https://twitter.com/gf_256'>@gf_256</ExternalLink>
                  </Paragraph>
                </div>
              </div>
              <br />
              {/* <Paragraph>
                Interested in sponsoring? Shoot us an email at <ExternalLink href="mailto:contact@tjctf.org">contact@tjctf.org</ExternalLink>!
              </Paragraph> */}
            </InfoCard>
          </Grid>
        </Flex>
        <Box
          sx={{
            bg: 'footer',
            color: 'unset',
            width: '100%',
            borderTop: 'rgba(255, 255, 255, 0.1)',
            borderTopStyle: 'solid',
          }}
        >
          <Flex
            sx={{
              justifyContent: 'end',
              m: 'auto',
              maxWidth: 'container',
              width: '90%',
              py: 3,
              gap: 2,
            }}
          >
            {socials.map(({ name, href, icon }, i) => (
              <FooterIcon
                key={i}
                href={href}
                Icon={icons[icon]}
                title={name}
                aria-label={name}
              />
            ))}
          </Flex>
        </Box>
      </motion.div>
    </Box >
  )
}

export default IndexPage
