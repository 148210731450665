/** @jsx jsx */

import { jsx } from 'theme-ui'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { fadeInUp } from '../animation/animations'

const InfoCard = ({ children, ...props }) => {
  const [ref, inView] = useInView({
    triggerOnce: true
  })
  return (
    <motion.div
      ref={ref}

      sx={{
        bg: 'background',
        p: 4,
        position: 'relative',
        border: 'solid',
        borderWidth: 3,
        borderColor: 'primary',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}

      initial='initial'
      animate={inView ? 'animate' : 'none'}
      variants={fadeInUp()}

      {...props}
    >
      {children}
    </motion.div>
  )
}

export default InfoCard
